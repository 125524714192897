import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../components/Header";
import { t } from "../../resources/t";
import Footer from "../../components/Footer";
import Slider, { ProductSlider } from "../../components/Slider";
import { ProductsTypes } from "../../models";
import axios from "../../axios";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading";

const ProductDetailPage: React.FC = () => {
  const params = useLocation();

  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [productDetail, setProductDetail] = useState<ProductsTypes | any>(null);
  const [viewedProducts, setViewedProducts] = useState<ProductsTypes[]>([]);

  const visibleCharacteristics = showAll ? productDetail?.characteristics : productDetail?.characteristics.slice(0, 5);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/client/product_list/?viewed=true`)
      .then((response) => {
        setViewedProducts(response.data);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/client/product_list/${params.state.product_id}/`)
      .then((response) => {
        setProductDetail(response.data);
      }).finally(() => {
        setLoading(false);
      });
  }, [params.state.product_id]);

  if (loading) return <Loading />;

  return (
    <div className="productDetail">
      <div
        className="productDetail-bgImage"
      // style={{
      //   backgroundImage: productDetail?.image ?
      //     `url(${process.env.REACT_APP_API_BASE_URL}${productDetail.image})`
      //     : require("../../assets/images/product-bg.png")
      // }}
      >
        <Header />
        <div className="productDetail-bgImage-content container">
          {/* <div></div> */}
          <img src={`${process.env.REACT_APP_API_BASE_URL}${productDetail && productDetail.image}`} alt="" />
          <div className="productDetail-bgImage-content-right">
            <div className="productDetail-bgImage-content-right-title">
              {productDetail && productDetail[`name_${localStorage.cur_lang}`]}
            </div>
            <div className="productDetail-bgImage-content-right-descr">
              {productDetail && productDetail[`description_${localStorage.cur_lang}`]}
            </div>
            <div>
              <button>
                <a href="https://maps.app.goo.gl/zKL2JNWsAiiR6AScA" target="_blank">
                  <span>{t("Где купить?")}</span>
                </a>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.46968 13.5292C5.32923 13.3886 5.25034 13.1979 5.25034 12.9992C5.25034 12.8004 5.32923 12.6098 5.46968 12.4692L9.43968 8.49918L5.46968 4.52918C5.396 4.46052 5.33689 4.37771 5.2959 4.28571C5.25491 4.19372 5.23287 4.0944 5.23109 3.9937C5.22931 3.893 5.24784 3.79297 5.28556 3.69958C5.32328 3.60619 5.37943 3.52136 5.45065 3.45014C5.52186 3.37892 5.6067 3.32278 5.70009 3.28505C5.79347 3.24733 5.8935 3.22881 5.99421 3.23059C6.09491 3.23236 6.19422 3.2544 6.28622 3.2954C6.37822 3.33639 6.46102 3.39549 6.52968 3.46918L11.0297 7.96918C11.1701 8.1098 11.249 8.30043 11.249 8.49918C11.249 8.69793 11.1701 8.88855 11.0297 9.02918L6.52968 13.5292C6.38906 13.6696 6.19843 13.7485 5.99968 13.7485C5.80093 13.7485 5.61031 13.6696 5.46968 13.5292Z"
                    fill="#FEFEFE"
                  />
                </svg>
              </button>
            </div>
            <div className="productDetail-bgImage-content-right-colors">
              {productDetail?.color.map((item: any) => (
                <div className="productDetail-bgImage-content-right-colors-item">
                  <div
                    className="productDetail-bgImage-content-right-colors-item-circle"
                    style={{ backgroundColor: item.color }}
                  ></div>
                  <span>{item[`name_${localStorage.cur_lang}`]}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {productDetail?.product_info.map((item: any) => (
        <div className="productDetail-info container" key={item.id}>
          <div className="productDetail-info-text">
            <div className="productDetail-info-text-title">
              {item[`title_${localStorage.cur_lang}`]}
            </div>
            <div className="productDetail-info-text-descr">
              {item[`description_${localStorage.cur_lang}`]}
            </div>
          </div>
          <img src={`${process.env.REACT_APP_API_BASE_URL}${item.image}`} alt="" />
        </div>
      ))}
      <div className="productDetail-characteristics">
        <div className="productDetail-characteristics-title">
          {t("Характеристики")}
        </div>
        <div className="productDetail-characteristics-list">
          {visibleCharacteristics?.map((item: any) => (
            <div
              className="productDetail-characteristics-list-item"
              key={item.id}
            >
              <span>{item[`name_${localStorage.cur_lang}`]}</span>
              <span>{item.unit}</span>
            </div>
          ))}
          {productDetail && productDetail.characteristics.length > 5 && (
            <button onClick={() => setShowAll(!showAll)}>
              <span>{showAll ? "-" : "+"}</span>
              <span>{showAll ? t("Меньше характеристик") : t("Больше характеристик")}</span>
            </button>
          )}
        </div>
      </div>
      <div className="productDetail-slider">
        <ProductSlider images={productDetail?.image_sliders} />
      </div>
      <div className="productDetail-slider">
        <div className="main-slider-title">{t("Просмотренные")}</div>
        <div className="main-slider-descr">
          {t("Товары, которые вы недавно просматривали.")}
        </div>
        <Slider products={viewedProducts} />
      </div>
      <div className="bottom-bgimage">
        <div className="bottom-bgimage-title">
          {t("Откройте для себя свой стиль")}
        </div>
        <div className="bottom-bgimage-descr">
          {t(
            "Ziffler – современный, доступный бренд, который развивается и предлагает фирменную бытовую технику для Вашего дома."
          )}
        </div>
        {/* <button>
          <span>{t("Подробнее")}</span>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.46968 13.5292C5.32923 13.3886 5.25034 13.1979 5.25034 12.9992C5.25034 12.8004 5.32923 12.6098 5.46968 12.4692L9.43968 8.49918L5.46968 4.52918C5.396 4.46052 5.33689 4.37771 5.2959 4.28571C5.25491 4.19372 5.23287 4.0944 5.23109 3.9937C5.22931 3.893 5.24784 3.79297 5.28556 3.69958C5.32328 3.60619 5.37943 3.52136 5.45065 3.45014C5.52186 3.37892 5.6067 3.32278 5.70009 3.28505C5.79347 3.24733 5.8935 3.22881 5.99421 3.23059C6.09491 3.23236 6.19422 3.2544 6.28622 3.2954C6.37822 3.33639 6.46102 3.39549 6.52968 3.46918L11.0297 7.96918C11.1701 8.1098 11.249 8.30043 11.249 8.49918C11.249 8.69793 11.1701 8.88855 11.0297 9.02918L6.52968 13.5292C6.38906 13.6696 6.19843 13.7485 5.99968 13.7485C5.80093 13.7485 5.61031 13.6696 5.46968 13.5292Z"
              fill="#FEFEFE"
            />
          </svg>
        </button> */}
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailPage;
